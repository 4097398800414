(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.blockFunctions = {

        
        /*
        * A wrapper function to handle calling all block related functionality
        */
        setup: function(){
            // Call all block functions from here.
            window.blockFunctions.faqs();
            window.blockFunctions.logoSlider();
            window.blockFunctions.testimonials();
            window.blockFunctions.productEnquire();
            window.blockFunctions.ajaxLoading();
        },

        /*
        * Logo Slider Functionality
        */
        logoSlider: function(){
            var logoSlider = $('.j-logo-slider');
            if ( logoSlider.length > 0 ){
                
                logoSlider.slick({
                    dots: false,
                    arrows: true,
                    autoSlidesToShow: true,
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: '0px',
                    responsive: [
                        {
                            breakpoint: 950,
                            settings: {
                              dots: false,
                              arrows: false,
                              autoSlidesToShow: true,
                              variableWidth: true,
                              centerMode: true,
                              centerPadding: '50px',
                            }
                        },
                          
                    ]
                });
            }
        },
        

        /*
        * FAQ Functionality
        */
        faqs: function(){
            var faqs = $('.j-faqs');
            if ( faqs.length > 0 ){
                faqs.on('click','.j-faqs--toggle', function(){
                    var faq = $(this).parents('.j-faqs--faq');
                    faq.toggleClass('is_active').find('.j-faqs--body').slideToggle();
                });
            }
        },

        /*
        * Testimonails Functionality
        */
        testimonials: function(){
            var testimonials = $('.j-testimonials');
            if ( testimonials.length > 0 ){
                testimonials.slick({
                    dots: false,
                    arrows: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    centerMode: true,
                    centerPadding: '200px',
                    responsive: [
                        {
                          breakpoint: 2000,
                          settings: {
                            dots: false,
                            arrows: true,
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            centerMode: true,
                            centerPadding: '200px',
                          }
                        },
                        {
                            breakpoint: 1700,
                            settings: {
                              dots: false,
                              arrows: true,
                              slidesToShow: 2,
                              slidesToScroll: 2,
                              centerMode: true,
                              centerPadding: '150px',
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                              dots: false,
                              arrows: true,
                              slidesToShow: 2,
                              slidesToScroll: 2,
                              centerMode: true,
                              centerPadding: '75px',
                            }
                        },
                        {
                            breakpoint: 800,
                            settings: {
                              dots: false,
                              arrows: true,
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              centerMode: false,
                            }
                        },
                          
                    ]
                });
            }
        },

        /*
        * Enquire Quote Functionality
        */
        productEnquire: function(){
            $('.j-enquire--open').on('click', function(){
                $('.j-enquire--body').slideDown();
            });
            $('.j-enquire--toggle').on('click', function(){
                $('.j-enquire--body').slideToggle();
            });
        },
        /*
        * Ajax Load More Posts Functionality
        */
        ajaxLoading: function(){
            $('.j-ajax').on('click', '.j-ajax--button', function(e){
                e.preventDefault();
                window._fetch.fetch( $(this).attr('href'), function(fetched){
                    $('.j-ajax').find('.j-ajax--posts').append(fetched.find('.j-ajax--posts').html() );
                    $('.j-ajax').find('.j-ajax--pagination').html(fetched.find('.j-ajax--pagination').html() );
                });
            });
        },


        /*
        * Product Image carousels
        */
        productImageSlider: function(){
            console.log('test');
            var imageSlider = $('.j-product-images');
            if ( imageSlider.length > 0 ){
                
                imageSlider.slick({
                    dots: true,
                    arrows: false,
                    fade: true,
                    adaptiveHeight: true,
                    customPaging: function(slick,index) {
                        return '<img src='+$(slick.$slides[index]).find('img').data('thumb')+')">';
                    }
                });
            }
        },
    };

}(window.jQuery, window, document)); // End of jQuery wrapper

