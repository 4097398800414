(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.headerFunctions = {

        header : $('.o-header'),
        body : $('body'),

        /*
        * A wrapper function to handle calling all header related functionality
        */
        setup: function(){
            window.headerFunctions.mobileMenuTrigger();
            window.headerFunctions.mobileMenuSubMenu();
        },

        /*
        * Mobile Menu Toggle Functionality
        */
        mobileMenuTrigger: function(){
            window.headerFunctions.header.on('click', '.j-mobile--toggle', function () {
                window.headerFunctions.body.toggleClass('s-menu-open');
                $(this).toggleClass('is-active');
            });
        },

        /*
        * Mobile Menu Sub Menu slide functionality
        */
        mobileMenuSubMenu: function(){
            window.headerFunctions.header.find('.sub-menu').siblings('a').append('<span class="menu-slide"><span class="menu-slide--inner"></span></span>');
            window.headerFunctions.header.find('.menu-slide').on('click',function(e){
                e.preventDefault();
                e.stopPropagation();
                $(this).parent().toggleClass('open');
                $(this).parent().siblings('.sub-menu').stop(true, true).slideToggle(200);
            });
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper

